// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_td d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_tf d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_tg d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_th d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_tj d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_tk d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_tl d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_rw q_rw";
export var heroExceptionNormal = "v_rx q_rx";
export var heroExceptionLarge = "v_ry q_ry";
export var Title1Small = "v_rb q_rb q_qH q_qJ";
export var Title1Normal = "v_rc q_rc q_qH q_qK";
export var Title1Large = "v_rd q_rd q_qH q_qL";
export var BodySmall = "v_rq q_rq q_qH q_q0";
export var BodyNormal = "v_rr q_rr q_qH q_q1";
export var BodyLarge = "v_rs q_rs q_qH q_q2";