// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "w_jQ d_jQ d_cv";
export var galleryMasonryImage = "w_jP d_jP d_w d_bR d_dB";
export var alignLeft = "w_qh d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_qj d_fr d_bH d_dx";
export var galleryContainer = "w_tm d_dW";
export var galleryContainerFull = "w_tn d_dT";
export var galleryRowWrapper = "w_tp d_cc";
export var galleryGuttersImage = "w_jS d_jS d_K d_cv";
export var galleryNoGuttersImage = "w_jR d_jR d_K d_cD";
export var galleryTextGutters = "w_jM d_jM d_cw";
export var galleryTextNoGutters = "w_jN d_jN d_cw";
export var galleryTextMasonry = "w_tq d_jM d_cw";
export var galleryImageWrapper = "w_tr d_fg d_Z";
export var descText = "w_ts d_jT d_0 d_9 d_7 d_8 d_n";
export var guttersDesc = "w_tt";