// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "y_tC d_bD";
export var storyRowWrapper = "y_hx d_hx d_bK";
export var storyLeftWrapper = "y_tD d_bz d_bP";
export var storyWrapperFull = "y_tF d_cD";
export var storyWrapperFullLeft = "y_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "y_mS d_hy";
export var storyLeftWrapperCenter = "y_tG d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "y_tH d_hF";
export var storyHeader = "y_tJ d_hD d_w d_cs";
export var storyHeaderCenter = "y_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "y_hB d_hB d_by d_dw";
export var storyBtnWrapper = "y_tK d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "y_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "y_qB d_fg d_Z";
export var imageWrapperFull = "y_tL d_w d_H d_bf d_Z";
export var SubtitleSmall = "y_qd q_qd q_qH q_qT";
export var SubtitleNormal = "y_qf q_qf q_qH q_qV";
export var SubtitleLarge = "y_qg q_qg q_qH q_qW";
export var textLeft = "y_dv";
export var textCenter = "y_dw";
export var textRight = "y_dx";